import React from "react"
import { Row, Col } from "reactstrap"

export default function UserFormView(props) {
  const overView = () => {
    const { username, externalEmail, organization } = props.user
    return (
      <div style={{ width: "100%" }}>
        <Row className="mb-12">
          <Col xm={6} sm={6} md={6} className="text-right">
            Username:
          </Col>
          <Col>{username}</Col>
        </Row>
        <Row className="mb-12">
          <Col xm={6} sm={6} md={6} className="text-right">
            Email:
          </Col>
          <Col>{externalEmail}</Col>
        </Row>
        <Row className="mb-12">
          <Col xm={6} sm={6} md={6} className="text-right">
            Organization:
          </Col>
          <Col>{organization}</Col>
        </Row>
      </div>
    )
  }
  return overView()
}
