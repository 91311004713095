import React, { useState, useRef, useEffect } from "react"
import { Form, Button, Row, Col } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_post } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import SearchHeader from "../../../components/support/input-search/users"
import UserView from "../../../components/support/user/user-view"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import _ from "lodash"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const ResetPassword = () => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const [savingData, setSavingData] = useState(false)
  const [selectUser, setSelectUser] = useState(null)
  const [ticketLink, setTicketLink] = useState("")
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const childRef = useRef()

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    let params = {}
    if (!index) {
      params = { parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          _.isNil(index)
            ? `ui/support/users/${selectUser.externalEmail}/resetpassword`
            : `ui/support/users/${selectUser.externalEmail}/resetpassword?index=${index}`,
          params,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while resetting password: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  return (
    <Layout pageTitle={`Request Password Reset`}>
      <Header>Request Password Reset</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectUser(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectUser ? (
              <>
                <Row className="mb-2">
                  <UserView
                    user={selectUser}
                    mode="v"
                    orgIndex={index}
                    isSuperAdmin={isSuperAdmin}
                    selectParentOrg={name}
                  />
                </Row>
                <Form method="POST" onSubmit={handleSubmit} className="justify-content-center">
                  <Row className="justify-content-center mb-2">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!selectUser || savingData || (!index && !parentOrganization)}
                      color={"primary"}
                    >
                      {savingData ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Saving...</span>
                        </span>
                      ) : (
                        "Password reset"
                      )}
                    </Button>
                  </Row>
                </Form>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default ResetPassword
