import React, { useState, useEffect } from "react"
import { useUserOrgs } from "../../../hooks/use-user"
import { fetch_get } from "../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { useSearch } from "../../../hooks/use-search"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import { MdClose } from "react-icons/md"
import _ from "lodash"

const Index = props => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    currentOrg: { index },
  } = useUserOrgs()
  const [loadingData, setLoadingData] = useState(false)
  const [searchUsers, setSearchUsers] = useState([])
  const [errorMeaasge, setErrorMeaasge] = useState("")

  const callback = v => {
    filterUsers(v?.target?.value || "")
  }

  const { callback: handleSearchValue } = useSearch(callback)

  useEffect(() => {
    document.getElementById("userSearchIcon").parentNode.parentNode.click()
    setErrorMeaasge("")
    props.selectData(null)
    setSearchUsers([])
    filterUsers("")
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentOrganization])

  const filterUsers = searchString => {
    setErrorMeaasge("")
    setSearchUsers([])
    props.selectData(null)
    if (!index && !props.parentOrganization) {
      setErrorMeaasge("Please select parent organization")
      return
    }
    // if (searchString) {
      let url = index
        ? `users?index=${index}&page=1`
        : `users?page=1&parentOrganization=${props.parentOrganization}`
      if (props.services) {
        url = url + `&service=${props.services}`
      }
      if (searchString) {
        url = url + `&query=${searchString}`
      }
      setLoadingData(true)
      getAccessTokenSilently()
        .then(accessToken => fetch_get(url, accessToken))
        .then(response => {
          setSearchUsers(_.differenceBy(response["data"] || [], props.whitelist || [], "username"))
          setLoadingData(false)
        })
        .catch(error => {
          console.log(`Error while getting list of users: ${error}`)
          Sentry.captureException(error)
          setSearchUsers([])
          props.selectData(null)
          setErrorMeaasge("An error occurred while getting the list of users.")
          setLoadingData(false)
        })
    // } else {
    //   setSearchUsers([])
    //   props.selectData(null)
    //   setLoadingData(false)
    // }
  }

  return (
    <Autocomplete
      id="UserSearch"
      fullWidth
      noOptionsText={
        loadingData ? "Loading..." : errorMeaasge ? errorMeaasge : "No user to select."
      }
      options={searchUsers}
      filterOptions={option => option}
      loading={loadingData}
      closeIcon={
        <MdClose
          id="userSearchIcon"
          onClick={() => {
            filterUsers("")
          }}
        />
      }
      onChange={(event, value, reason) => {
        if (reason === "clear") {
          props.selectData(null)
        } else if (reason === "select-option") {
          props.selectData(value)
        }
      }}
      getOptionLabel={option => option.displayName}
      renderOption={option => {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              style={{ width: "100%", padding: "6px 16px" }}
              onClick={() => {
                props.selectData(option)
                filterUsers(option.displayName)
              }}
            >
              {option.displayName}
            </div>
          </>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Search for a user..."
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            onChange: event => handleSearchValue(event),
            endAdornment: (
              <React.Fragment>
                {loadingData ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default Index
